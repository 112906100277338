import React from "react";

const faqs = [
  {
    question: "What is Secure Check, and how does it work?",
    answer:
      "Secure Check is a web-based platform designed to offer fast and reliable identity verification services. Our system allows you to verify personal and corporate identities through various tools such as NIN, Driver's License, BVN, CAC, and International Passport verification. Simply sign up, choose the service you need, provide the required information, and our platform will handle the rest with secure and accurate results.",
  },
  {
    question: "How fast is the verification process on Secure Check?",
    answer:
      "Our verification process is fast and efficient, with most verifications being completed within minutes. For certain services, the time may vary depending on the complexity, but we ensure timely and reliable results without compromising accuracy.",
  },
  {
    question: "Is my data safe when using Secure Check?",
    answer:
      "Absolutely! At Secure Check, we prioritize data security and use advanced encryption protocols to ensure that your personal and corporate information is protected. We are fully compliant with data protection regulations and safeguard your data throughout the verification process.",
  },
  {
    question: "What types of verifications can I perform on Secure Check?",
    answer:
      "Secure Check offers a variety of identity verification services, including: NIN Verification, Driver's License Verification, BVN Verification, CAC Verification, and International Passport Verification. These services help ensure the authenticity of individual and corporate identities.",
  },
  {
    question: "How do I get started with Secure Check?",
    answer:
      "Getting started is easy! Simply create an account on our platform, select the type of verification service you need, and follow the on-screen instructions to submit the required details. Once you complete the process, you'll receive the verification results promptly.",
  },
];

const FaqSection = () => {
  return (
    <section className="section section-lg has-shape">
      <div className="nk-shape bg-shape-border-e ms-n25p mt-2 start-50 translate-middle-x"></div>
      <div className="container">
        <div className="nk-shape bg-shape-wormhole-a mt-n45p mt-xl-n40p mt-xxl-n35p ms-2 start-50 top-100 translate-middle-x"></div>
        <div className="section-head">
          <div className="row justify-content-center text-center">
            <div className="col-lg-9 col-xl-7 col-xxl-8">
              <h2 className="title h1">Questions &amp; Answers</h2>
              <p className="lead px-lg-10 px-xxl-9">
                Find answers to common inquiries about Secure Check’s services, process, security measures, and how to get started with identity verification.
              </p>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row g-gs justify-content-center">
            <div className="col-xl-9 col-xxl-8">
              <div className="accordion accordion-separated" id="faq-1">
                {faqs.map((faq, index) => (
                  <div
                    className="accordion-item border-0 bg-gradient-light"
                    key={index}
                  >
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#faq-1-${index + 1}`}
                      >
                        {faq.question}
                      </button>
                    </h2>
                    <div
                      id={`faq-1-${index + 1}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                      data-bs-parent="#faq-1"
                    >
                      <div className="accordion-body">{faq.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
