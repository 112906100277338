
import React from 'react';
import { Link } from "react-router-dom";

import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

import ServicesSection from '../components/ServicesSection';
import QuestionsAndAnswersSection from '../components/QuestionsAndAnswersSection';
import VerificationMadeEasy from '../components/VerificationMadeEasy';
import HowItWorksSection from '../components/HowItWorksSection';
import TestimonialsSection from '../components/TestimonialsSection';

import { PATH_AUTH } from '../routes/paths';
import BrandSection from '../components/BrandSection';

function Home() {

    return (
        <>
            <header className="nk-header has-shape">
                <div className="nk-shape bg-shape-blur-q mt-n20p ms-10p start-50 translate-middle-x"></div>
                <div className="nk-shape bg-shape-blur-r mt-n10p ms-n10p start-50 translate-middle-x"></div>
                <div className="nk-shape bg-shape-border-a mt-n10 ms-40p start-50 top-100 translate-middle-x"></div>
                <div className="nk-shape bg-shape-border-b mt-n9 ms-30p start-50 translate-middle-x"></div>
                <div className="nk-shape bg-shape-border-c mt-12 ms-n40p start-50 translate-middle-x"></div>
                <div className="nk-header-main nk-menu-main will-shrink is-transparent ignore-mask">
                    <div className="container">
                        <div className="nk-header-wrap">
                            <Logo />

                            <Menu />
                        </div>
                    </div>
                </div>
                <div className="nk-hero pt-4 pt-lg-6 pt-xl-12 pb-xl-4">
                    <div className="container">
                        <div
                            className="row g-gs align-items-center justify-content-center justify-content-xl-between flex-xl-row-reverse text-center text-xl-start"
                        >
                            <div className="col-xl-6 col-xxl-5 col-lg-7 col-md-10">
                                <div className="nk-hero-gfx me-xxl-n7">
                                    <div className="p-1 rounded-3">
                                        <img
                                            className="w-100 rounded-3"
                                            src="./assets/images/header.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-7 col-xl-6 col-lg-11">
                                <div className="nk-hero-content">
                                    <h5 className="text-uppercase fw-normal mb-3">
                                        Welcome to Secure Check
                                    </h5>
                                    <h1 className="title mb-3 mb-lg-4">
                                        Building trust in the way we .
                                        <span className="text-gradient-primary">&nbsp; work, live and learn</span>
                                        &nbsp;
                                    </h1>
                                    <p className="lead">
                                        Empowering global businesses to deliver reliable services by effortlessly creating, verifying, and accessing trusted digital identities.
                                    </p>
                                    <div class="pt-2 pb-5">
                                        <form action="#">
                                            <div class="d-flex flex-column flex-sm-row bg-white rounded-3 p-2 mx-sm-4 mx-lg-0 me-xxl-11">
                                                <div class="d-flex align-items-center flex-grow-1">
                                                    <div class="text-primary me-3 ps-3 fs-4">
                                                        <em class="icon ni ni-check"></em>
                                                    </div>
                                                    <div class="form-group flex-grow-1">
                                                        <div class="form-control-wrap">
                                                            <input class="form-control-plaintext" type="text" placeholder="Enter your NIN Number" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group mt-2 mt-sm-0">
                                                    <button class="btn btn-lg btn-primary w-100">Verify NIN</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <ul className="btn-list btn-list-inline py-3 gy-3">
                                        <li>
                                            <Link to={PATH_AUTH.signUp} className="btn btn-primary btn-lg">
                                                <span>Sign Up</span>
                                                <em className="icon ni ni-arrow-long-right"></em>
                                            </Link>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <main className="nk-pages">
                <ServicesSection />
                <VerificationMadeEasy />
                <HowItWorksSection />
                {/* <TestimonialsSection />
                <BrandSection /> */}

                <QuestionsAndAnswersSection />
            </main>
            <Footer />
        </>
    );
}

export default Home;
