import React from 'react';

// FeatureCard Component
const FeatureCard = ({ title, description, image }) => {
  return (
    <div className="col-lg-4">
      <div className="card bg-gradient-light border-0 h-100">
        <div className="card-body p-3 d-flex">
          <div className="feature feature-inline align-items-center">
            <div className="feature-text me-auto">
              <h4 className="title">{title}</h4>
              <p>{description}</p>
            </div>
            <div className="feature-decoration flex-shrink-0 ms-4 me-n3">
              <img src={image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// VerificationMadeEasy Component
const VerificationMadeEasy = () => {
  const features = [
    {
      title: 'Performance Optimization',
      description:
        'Secure Check delivers fast and efficient identity verification with real-time results, ensuring top-notch accuracy and performance.',
      image: './assets/images/1-light.png',
    },
    {
      title: 'SEO Enhancement',
      description:
        'Our platform enhances your credibility and online presence by providing verified, trustworthy identities that improve user engagement and SEO rankings.',
      image: './assets/images/2-light.png',
    },
    {
      title: 'Scalability & Flexibility',
      description:
        'Secure Check scales effortlessly to meet both small and large verification needs, offering flexible services that grow with your business.',
      image: './assets/images/3-light.png',
    },
  ];

  return (
    <section className="section section-lg section-bottom-0 has-shape">
      <div className="nk-shape bg-shape-border-d mt-n8 mt-lg-n9 start-50 translate-middle-x"></div>
      <div className="container">
        <div className="section-head">
          <div className="row justify-content-center text-center">
            <div className="col-lg-9 col-xl-7">
              <h2 className="title">Verification Made Easy</h2>
              <p className="lead">
                Secure Check simplifies the identity verification process, offering fast, reliable, and secure services that save you time while ensuring accuracy and compliance.
              </p>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row g-gs">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                image={feature.image}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerificationMadeEasy;
