import React from 'react';

// FeatureItem Component
const FeatureItem = ({ iconClass, title, description }) => {
  return (
    <div className="col-md-6">
      <div className="feature feature-inline">
        <div className="feature-media">
          <div className="text-gradient-primary fs-2 d-inline-flex">
            <em className={`icon ${iconClass}`}></em>
          </div>
        </div>
        <div className="feature-text">
          <h4 className="title">{title}</h4>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

// HowItWorksSection Component
const HowItWorksSection = () => {
  const features = [
    {
      iconClass: 'ni ni-note-add',
      title: 'Focus on Innovation, Not Paperwork',
      description: 'Secure Check handles all your identity verification needs, freeing up your time to focus on innovation and developing creative solutions.',
    },
    {
      iconClass: 'ni ni-bulb',
      title: 'Streamline Operations for Creative Freedom',
      description: 'Our platform reduces administrative burdens, letting you invest more energy in brainstorming new ideas and implementing creative strategies.',
    },
    {
      iconClass: 'ni ni-activity-round',
      title: 'Build Trust with Verified Identities',
      description: 'With reliable verification of individuals and businesses, you can build trust with clients and partners, fostering a creative environment where collaboration thrives.',
    },
    {
      iconClass: 'ni ni-text-a',
      title: 'Simplify Complex Tasks',
      description: 'Secure Check automates the complex process of identity verification, allowing you to channel your mental energy into more creative and strategic work.',
    },
    {
      iconClass: 'ni ni-text2',
      title: 'Scale Creatively Without Boundaries',
      description: "Whether you're verifying one individual or thousands, Secure Check scales effortlessly, enabling you to take on more creative projects as your business grows.",
    },
    {
      iconClass: 'ni ni-puzzle',
      title: 'Foster a Secure Environment for Creative Expression',
      description: 'By ensuring accurate and secure identity verification, Secure Check helps create a safe space for creative collaboration without the fear of fraud or misrepresentation.',
    },
    {
      iconClass: 'ni ni-presentation',
      title: 'Enhance Your Online Presence with Credibility',
      description: 'Verified identities contribute to stronger trust and credibility, which boosts your reputation online, helping you reach new audiences creatively and confidently.',
    },
    {
      iconClass: 'ni ni-happy',
      title: 'Empower Agile Decision-Making',
      description: 'With fast and accurate verification services, Secure Check empowers you to make informed decisions quickly, allowing for more agile and creative responses to new opportunities.',
    },
  ];

  return (
    <section className="section section-lg section-bottom-0">
      <div className="container">
        <div className="section-head">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10 col-xl-9 col-xxl-8">
              <h2 className="title h1">How will Secure Check unlock your creative potential?</h2>
              <p className="lead">
              Secure Check unlocks your creative potential by streamlining content creation, enhancing productivity, and freeing up time for innovation and collaboration.
              </p>
            </div>
          </div>
        </div>
        <div className="section-content">
          <div className="row gy-gs gx-xxl-9">
            {features.map((feature, index) => (
              <FeatureItem
                key={index}
                iconClass={feature.iconClass}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
