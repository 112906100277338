import React from 'react';

const servicesData = [
    {
        id: 'nin-verification',
        title: 'NIN Verification',
        icon: 'ni-flag',
        imgSrc: '/assets/images/services/nin.png',
        heading: 'Streamline Your NIN Registration and Verification Process',
        description: 'Easily manage and track your National Identification Number (NIN) registrations with our intuitive platform.',
        points: [
            'Effortless Document Upload and Tagging: Organize and track NIN applications by date, applicant name, or status using our smart tagging feature, making your registration process more efficient.',
            'Comprehensive Identity Management: Ensure all your details are securely recorded and easily accessible. Arrange your NIN registration and verification steps in one streamlined process.',
            'Flexible Payment Options: Pay for your NIN registration and related services securely using multiple payment options. Our platform integrates with over 50+ payment methods, supporting both individual and bulk payments with ease.'
        ]
    },
    {
        id: 'driver-license',
        title: 'Driver\'s License',
        icon: 'ni-globe',
        imgSrc: '/assets/images/services/drivers-license.png',
        heading: 'Your Trusted Resource for Nigeria Driver’s License Information',
        description: 'The content on your website plays a crucial role in guiding visitors to take action. To help people stay engaged and take the steps to obtain their Nigeria Driver’s License, your website needs to be clear, concise, and engaging, while providing valuable and relevant information.',
        points: [
            'Establish Trust: By offering accurate and helpful details about the Nigeria Driver’s License application process, requirements, and renewal options, you position yourself as an authority in the field, building trust with your audience.',
            'Build Credibility: A well-organized website with high-quality content that appears at the top of search results will boost your credibility. Make it easier for people to find everything they need to know about obtaining, renewing, and verifying their Nigeria Driver’s License.'
        ]
    },
    {
        id: 'bvn-verification',
        title: 'BVN Verification',
        icon: 'ni-edit',
        imgSrc: '/assets/images/services/bvn.png',
        heading: 'Simplify Your BVN Registration and Management',
        description: 'Easily manage your Bank Verification Number (BVN) details with our user-friendly platform, designed to streamline the entire process for you.',
        points: [
            'Track and Manage BVN-Linked Accounts: Organize and monitor your BVN-related activities by linking multiple bank accounts, categorizing transactions by date, bank, or account holder name, ensuring you have a clear overview of all your financial records.',
            'Efficient Data Arrangement: Arrange and review your BVN registration history and account details quickly and effortlessly. Keep all your BVN-related transactions and updates in one place for easy access.',
            'Multiple Payment Options: Pay for BVN-related services securely with the flexibility of over 50+ integrated payment methods. Our platform also supports bulk payments for added convenience.'
        ]
    },
    {
        id: 'cac-verification',
        title: 'CAC Verification',
        icon: 'ni-briefcase',
        imgSrc: '/assets/images/services/cac.png',
        heading: 'Streamline Your CAC Verification Process',
        description: 'Easily verify and manage your business registration with the Corporate Affairs Commission (CAC) using our efficient platform, designed to simplify your verification needs.',
        points: [
            'Comprehensive Business Analysis: Analyze your business registration details effortlessly through our group transaction tagging feature, allowing you to keep track of multiple verifications and their statuses.',
            'Organize Your Verification Records: Arrange your CAC verification data by date, business name, or registration number, ensuring you have quick access to essential information whenever you need it.',
            'Flexible Payment Solutions: Simplify the payment process for your CAC verification services by adding multiple payment cards. Our platform integrates with over 50+ payment methods and supports bulk payments for your convenience.'
        ]
    },
    {
        id: 'international-passport-verification',
        title: 'International Passport Verification',
        icon: 'ni-briefcase',
        imgSrc: '/assets/images/services/international-passport.png',
        heading: 'Streamline Your International Passport Verification Process',
        description: 'Effortlessly verify and manage your international passport details using our platform, designed to simplify the entire verification process for you.',
        points: [
            'Comprehensive Passport Analysis: Analyze your passport information with ease through our smart tagging feature, allowing you to track multiple verification statuses and ensure your travel documents are up-to-date.',
            'Organize Your Verification Records: Arrange your passport verification data by date of issue, passport number, or applicant name, making it easier to retrieve vital information whenever you need it.',
            'Flexible Payment Solutions: Simplify payments for your international passport verification services by adding multiple payment methods. Our platform integrates with over 50+ payment options and supports bulk payments for seamless processing.',
        ]
    },
];

const ServicesSection = () => {
    return (
        <section className="section section-lg">
            <div className="container">
                <div className="section-head">
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-9 col-xl-8 col-xxl-6">
                            <h6 className="overline-title text-primary">Secure Check Overview</h6>
                            <h2 className="title">Verification Process Utilizing Multiple Identification Methods.</h2>
                        </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="overflow-hidden">
                        <ul className="nav nav-tabs nav-tabs-stretch mb-5" role="tablist">
                            {servicesData.map((service, index) => (
                                <li className="w-100 w-sm-50 w-lg-auto" key={index}>
                                    <button className={`nav-link w-100 w-lg-auto ${index === 0 ? 'active' : ''}`} data-bs-toggle="tab" data-bs-target={`#${service.id}`} aria-selected={index === 0} role="tab">
                                        <em className={`icon ni ${service.icon}`}></em>
                                        <span>{service.title}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="tab-content p-5 card border-0 rounded-4 shadow-sm">
                        {servicesData.map((service, index) => (
                            <div className={`tab-pane p-lg-3 fade ${index === 0 ? 'active show' : ''}`} id={service.id} key={index} role="tabpanel">
                                <div className="row g-gs flex-xl-row-reverse">
                                    <div className="col-xl-6 col-lg-10">
                                        <div className="block-gfx">
                                            <img className="w-100 rounded-3" src={service.imgSrc} alt="" />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="block-text pe-xl-7">
                                            <h3 className="mb-4">{service.heading}</h3>
                                            <p className="lead">{service.description}</p>
                                            <ul className="list gy-3">
                                                {service.points.map((point, pointIndex) => (
                                                    <li key={pointIndex}>
                                                        <em className="icon text-primary ni ni-check-circle-fill"></em>
                                                        <span>{point}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
