
import React, { useEffect } from 'react';
import { PATH_AUTH, PATH_PAGE } from '../routes/paths';

function Menu() {

    const menuRouter = (path) => {
        // console.log(path);
        window.location.href = path;
    };

    const handleClick = () => {
        if (localStorage.getItem('is-dark')) {
            localStorage.removeItem('is-dark');
        } else {
            localStorage.setItem('is-dark', 'is-dark');
        }
    }

    const changeTopointer = {
        cursor: 'pointer'
    };

    useEffect(() => {
        if (localStorage.getItem('is-dark') === 'is-dark') {
            document.body.classList.add("is-dark")
        } else {
            document.body.classList.remove("is-dark")
        }
    }, []);

    return (
        <nav className="nk-header-menu nk-menu">
            <ul className="nk-menu-list mx-auto">
                <li className="nk-menu-item">
                    <span
                        onClick={() => menuRouter(PATH_PAGE.root)}
                        className="nk-menu-link" style={changeTopointer}
                    >
                        <span className="nk-menu-text">Home</span>
                    </span>
                </li>
                <li className="nk-menu-item">
                    <span
                        onClick={() => menuRouter(PATH_PAGE.features)}
                        className="nk-menu-link" style={changeTopointer}
                    >
                        <span className="nk-menu-text">Features</span>
                    </span>
                </li>
                <li className="nk-menu-item">
                    <span
                        onClick={() => menuRouter(PATH_PAGE.pricing)}
                        className="nk-menu-link" style={changeTopointer}
                    >
                        <span className="nk-menu-text">Pricing</span>
                    </span>
                </li>
                <li className="nk-menu-item">
                    <span
                        onClick={() => menuRouter(PATH_PAGE.blog)}
                        className="nk-menu-link" style={changeTopointer}
                    >
                        <span className="nk-menu-text">Blog</span>
                    </span>
                </li>
                <li className="nk-menu-item">
                    <span
                        onClick={() => menuRouter(PATH_PAGE.contact)}
                        className="nk-menu-link" style={changeTopointer}
                    >
                        <span className="nk-menu-text">Contact Us</span>
                    </span>
                </li>
                {/* <li className="nk-menu-item">
                    <Link
                        to={PATH_PAGE.documentation}
                        className="nk-menu-link"
                    >
                        <span className="nk-menu-text">Documentation</span>
                    </Link>
                </li> */}
            </ul>
            <div className="mx-2 d-none d-lg-block">
                <button className="dark-mode-toggle dark-active" onClick={event => handleClick(event)}>
                    <em className="off icon ni ni-sun-fill"></em>
                    <em className="on icon ni ni-moon-fill"></em>
                </button>
            </div>
            <ul className="nk-menu-buttons flex-lg-row-reverse">
                <li>
                    <a href={PATH_AUTH.signUp} className="btn btn-primary">
                        Signup
                    </a>
                </li>
                <li>
                    <a className="link link-dark" href={PATH_AUTH.signIn}>
                        Login
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default Menu;
