function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_HOME = '/';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_PAGE = {
    root: ROOTS_HOME,
    features: '/features',
    pricing: '/pricing',
    blog: '/blog',
    contact: '/contact',
    documentation: '/documentation',
    termsCondition: '/terms-condition',
    page404: '/404',
}

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    signIn: 'https://app.securecheck.com/auth/signin',
    signUp: 'https://app.securecheck.com/auth/signup',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    dashboard: path(ROOTS_DASHBOARD, '/app'),
}

export const PATH_DOCS = ROOTS_DASHBOARD;